<template>
    <auto-complete v-model="product" :items="productList" title="title" subtitle="code" label="Products"
        v-bind="$attrs">
        <template v-slot:selection="data">
            <v-chip :input-value="data.selected">
                {{ data.item.title }}
            </v-chip>
        </template>
        <template v-slot:default="item">
            <slot :item="item">
                <v-list-item>
                    <v-list-item-icon>
                        <image-card :src="item.item?.images[0]?.path" :width="64" :height="64"></image-card>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n4">
                        <v-list-item-title>{{ item.item.title }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.item.code }}</v-list-item-subtitle>
                        <v-list-item-subtitle>Price <v-icon small>mdi-currency-inr</v-icon>{{ item.item.price
                            }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </slot>
            <v-divider></v-divider>
        </template>
    </auto-complete>
</template>

<script>
import AutoComplete from '../../../components/AutoComplete.vue'
import ImageCard from '../../../components/ImageCard.vue';
import appConstants from '../../../utils/appConstants';
export default {
    components: { AutoComplete, ImageCard },
    props: {
        value: {
            type: [Object, Array]
        },
        variant: {
            type: String,
            validator: function (value) {
                return ['all', 'parentOnly', 'allVariants'].includes(value);
            },
            default: () => 'allVariants'
        }
    },
    data() {
        return {
            productList: [],
        }
    },
    computed: {
        product: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue);
            }
        }
    },
    mounted() {
        this.loadProducts();
    },
    methods: {
        async loadProducts() {
            this.productList = (await this.getItem(`${appConstants.PRODUCTS_API}?variant=${this.variant}&size=-1`)).data
        }
    },
}
</script>

<style lang="scss" scoped></style>